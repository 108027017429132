<template>
  <div class="dependents-layout">
    <v-row :class="$vuetify.breakpoint.smAndDown ? 'mb-0' : 'mb-4'">
      <v-col class="header" cols="12">
        <span class="heading-2">{{ $t("general.sidebar.dependents") }}</span>
        <div class="action-wrap gap-1">
          <PrimaryButton
            v-if="showAddBtn"
            :app-view="true"
            left-icon="mdi-plus"
            text="Add child"
            @onClick="addDependent()"
          />
          <PrimaryButton
            v-if="showAddBtn"
            :app-view="true"
            left-icon="mdi-plus"
            text="Add adult"
            @onClick="addDependent('adult')"
          />
          <PrimaryButton
            v-if="!showAddBtn"
            :app-view="true"
            left-icon="mdi-menu"
            text="List"
            @onClick="listDependent"
          />
        </div>
      </v-col>
    </v-row>
    <router-view></router-view>
  </div>
</template>

<script>
import moment from "moment-timezone";

import { DependentsApi } from "@/api/dependents";
import PrimaryButton from "@/components/uikit/PrimaryButton";
import { routesEnum } from "@/types/Routes.enum";

export default {
  name: "Dependents",
  components: {
    PrimaryButton,
  },
  data: () => ({
    loading: false,
    dialog: false,
    requestConsultDialog: false,
    requestRemoveDialog: false,
    requestedDependent: {},
    requestedDependentRemove: {},
    dependents: [],
  }),
  computed: {
    showAddBtn() {
      return this.$route.name === routesEnum.patientDependents;
    },
  },
  methods: {
    addDependent(kind = "child") {
      this.$router.push({ name: routesEnum.patientDependentsRegister, query: { kind } });
    },
    listDependent() {
      this.$router.push({ name: routesEnum.patientDependents });
    },
    async onDelete(item) {
      this.requestRemoveDialog = true;
      this.requestedDependentRemove = item;
    },
    async update() {
      if (!this.uid) return;
      this.dialog = false;
      this.loading = true;
      this.dependents = await DependentsApi.getByInviterId(this.uid);
      this.dependents = this.dependents.sort((a, b) => moment(b.createdAt).valueOf() - moment(a.createdAt).valueOf());
      this.loading = false;
    },
  },
  async mounted() {
    try {
      await this.update();
    } catch (e) {
      console.error(e);
    }
  },
};
</script>

<style lang="scss" scoped>
.dependents-layout {
  padding: 30px 30px 80px;

  .action-wrap {
    display: flex;
    justify-content: right;
    @include tablet {
      justify-content: space-between;
    }
  }

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .heading-2 {
      font-style: normal;
      font-weight: 700;
      font-size: 20px;
      line-height: 25px;
    }
  }
}
</style>
