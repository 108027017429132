import { apiRequest } from "@/api/apiRequest";
import { CreateDependentsDto } from "@/types/createDependent";
import { GenderEnum } from "@/types/PractitionerResponseDto";

interface DependentsData {
  familyRelationship: string;
  firstName: string;
  lastName: string;
  gender: GenderEnum;
  birthDate: Date;
  socialSecurityNumber: string;
  email: string;
  phoneNumber: string;
  locale: string;
  inviterId: string;
}

const create = async (body: DependentsData) => {
  const { data } = await apiRequest.post("/dependents", body);
  return data;
};

const createV1 = async (body: CreateDependentsDto) => {
  const { data } = await apiRequest.post("/v1/dependents", body);
  return data;
};

const getByInviterId = async (inviterId: string) => {
  const { data } = await apiRequest.get(`/dependents/${inviterId}`);
  return data;
};

const getByInviterIdV1 = async (inviterId: string) => {
  const { data } = await apiRequest.get(`/v1/dependents/patient/${inviterId}/`);
  return data;
};

const getByInviterIdV1Minor = async (inviterId: string) => {
  const { data } = await apiRequest.get(`/v1/dependents/${inviterId}/`);
  return data;
};

const destroy = async (inviterId: string) => {
  const { data } = await apiRequest.delete(`/dependents/${inviterId}`);
  return data;
};

const requestConsult = async (body: { consultationMethod: string; phoneNumber: string; age?: string }) => {
  const { data } = await apiRequest.post("/dependents/consult", body);
  return data;
};

export const DependentsApi = {
  getByInviterIdV1Minor,
  create,
  createV1,
  getByInviterIdV1,
  destroy,
  getByInviterId,
  requestConsult,
};
